import '@babel/polyfill';
// Get DefaultModule
import {DefaultModule} from 'stein';
import 'legit-ripple/js/ripple';
import easing from 'jquery.easing';

import {Faq} from '../Lib/Faq';
import {Config} from 'Lib/Config';
import Typed from 'typed.js';
import Swiper from 'swiper';

import AOS from 'aos';

let pageConfig = Config.defaults({
    typewrite: false,
    customer_swiper: false
});

/**
 *
 */
export class Bootstrap extends DefaultModule {
    constructor() {
        super();

        const self = this;

        // Subscribe DOMReady functions to the DOMReady event
        if (typeof this.DOMReady === 'function') {
            this.subscribe('DOMReady', this.DOMReady);
        }

        // on DOMContentLoaded, publish DOMReady
        document.addEventListener('DOMContentLoaded', () => {
            this.publish('DOMReady');

            // noinspection JSUnresolvedFunction
            $('.ripple').ripple();

            $('[href="#"]').on('click', function(e) {
                e.preventDefault();
            });

            Faq.init();

            if (pageConfig.customer_swiper) {
                this.customerSwiper();
            }

            if (pageConfig.typewrite !== false) {
                this.typeWrite();
            }

            this.bindOffCanvasMenu();

            $(document).on('click', 'div.header_down_arrow', function() {
                $('html, body').animate({
                    scrollTop: $('#onze_modules').offset().top - 150
                }, 300);
            });

            $('#file_upload_application').on('click', function(e) {
                e.preventDefault();
                $('#ApplicationUpload').click();
            });

            $('#ApplicationUpload').on('change', function() {
                let file_name = $(this).val().split(/(\\|\/)/g).pop();

                if (file_name) {
                    $('#file_upload_application').text(file_name);
                }
            });

            $(document).on('click', 'div.module_header_down_arrow', function() {
                $('html, body').animate({
                    scrollTop: $('#arrow_down_target').offset().top - 150
                }, 300);
            });

            $(document).on('click', '[data-open-call-me-back-modal]', function(e) {
                e.preventDefault();
                $('body').addClass('modal-open');
                $('#call-me-back-modal').fadeIn(200);
            });

            $(document).on('click', '[data-job-modal]', function(e) {
                e.preventDefault();
                $('body').addClass('modal-open');
                $('#job-modal').fadeIn(200);
            });

            $(document).on('click', '[data-close-modal]', function() {
                self._closeModal();
            });

            $(document).on('click', '.modal_wrapper', function(e) {
                if ($(e.target).closest('.modal-content').length === 0) {
                    self._closeModal();
                }
            });

            $(document).on('click', '.module', function(e) {
                if ($(e.target).prop('tagName') !== 'A') {
                    e.preventDefault();
                    let targetUrl = $(this).find('a').prop('href');
                    $(location).prop('href', targetUrl);
                }
            });
            AOS.init();
        });

    }

    _closeModal() {
        $('.modal_wrapper').slideUp({duration: 200, easing: 'easeInOutExpo'});
        $('body').removeClass('modal-open');
    }

    bindOffCanvasMenu() {
        $(document).on('click', '.menu_dot', function() {
            $(document.body).addClass('offcanvas-open');
        });

        $(document).on('click', '.close_offcanvas', function() {
            $(document.body).removeClass('offcanvas-open');
        });

        $(document).on('click', function(e) {
            if ($(e.target).closest('#offcanvas-menu').length === 0 && !$(e.target).is($('.menu_dot'))) {
                $(document.body).removeClass('offcanvas-open');
            }
        });
    }

    typeWrite() {
        new Typed('.typewrite', {
            strings: pageConfig.typewrite,
            typeSpeed: 40,
            backDelay: 1200,
            loop: true
        });
    }

    customerSwiper() {
        new Swiper('.customer-swiper-container', {
            slidesPerView: 2,
            slidesPerColumn: 2,
            spaceBetween: 14,
            initialSlide: Math.floor(($('.customer-swiper-container .swiper-slide').length / 2) / 2),
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });

    }
}
